import { createContext, useContext, useState } from "react";
import { Organization } from "./organizationStore";

class OrganizationType  {
    organization: Organization | undefined = undefined
    setOrganization: Function = ()=> {}
}
  
export const OrganizationContext = createContext<OrganizationType>(new OrganizationType())

export const useOrganizationContext = () => {
    const organizationContext = useContext(OrganizationContext)
    if(organizationContext === undefined){
        throw new Error("useOrganizationContext must be used with OrganizationContext.Provider")
    }
    const {organization, setOrganization} = organizationContext

    if(organization === undefined) {
        throw new Error("OrganizationContext has null organization")
    }
    return {organization, setOrganization};
}
