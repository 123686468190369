import { createContext, useContext, useState } from "react";
import { CustomerData } from "../../common/CustomerModel";

export interface CustomerDataType  {
    customer: CustomerData | undefined
    setCustomer: Function 
}
  
export const CustomerContext = createContext<CustomerDataType | undefined>(undefined)

/**
 * 
 * @param acceptNull if acceptNull is true then useUserContext may return undefined user otherwise it will throw err if user is undefined
 * @returns user object and setUser method using which user can be updated
 */
export const useCustomerContext = () => {
    const customerContext = useContext(CustomerContext)
    if(!customerContext){
        throw new Error("useCustomerContext must be used with CustomerContext.Provider")
    }
    const {customer, setCustomer} = customerContext

    if(customer === undefined) {
        throw new Error("CustomerContext has null customer")
    }
    return {customer, setCustomer};
}