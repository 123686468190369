import { User } from "firebase/auth";
import { createContext, useContext, useState } from "react";

export interface UserType  {
    user: User | undefined
    setUser: Function 
}
  
export const UserContext = createContext<UserType | undefined>(undefined)

/**
 * 
 * @param acceptNull if acceptNull is true then useUserContext may return undefined user otherwise it will throw err if user is undefined
 * @returns user object and setUser method using which user can be updated
 */
export const useUserContext = () => {
    const userContext = useContext(UserContext)
    if(userContext === undefined){
        throw new Error("useUserContext must be used with UserContext.Provider")
    }
    const {user, setUser} = userContext

    if(user === undefined) {
        throw new Error("UserContext has null User")
    }
    return {user, setUser};
}
