import { ReactNode, useState } from "react"
import { UserContext } from "./userContext"
import { User } from "firebase/auth"
import { LoggedUserStore } from "./userStore"

type PropsType = {
  children: ReactNode
}

export const UserContextProvider = ({children}: PropsType) => {
  const loggedInUser = LoggedUserStore.getLoggedInUser()
  const [userInfo, setUserInfo] = useState<User | undefined>(loggedInUser)
  const userType = {
    user: userInfo,
    setUser: setUserInfo
  }
  return (<UserContext.Provider value={userType}>
    {children}
  </UserContext.Provider>)
}