import { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Card, ListGroup, Spinner } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { CustomerWallet, HisabReceipt } from '../../common/CustomerModel';
import { getNumericTextColor, getShortDateTimeString } from '../../common/utils/utils';
import { fetchCustomerReceipt, fetchTransactionsForReceipt } from '../../common/utils/FetchUtils';
import { UserContext } from '../../modules/signin/userContext';
import { CustomerTransactions, TransactionData } from '../../common/TransactionDataModel';
import TableContainer from '../tableContainer/TableContainer';

export const CustomerTxnTableColumnsData = [
    {
        Header: "Time",
        size: 200,
        accessor: (value: CustomerTransactions) => {
            const date = new Date(value.time);
            const day = new Intl.DateTimeFormat("en-IN", {
                day: "2-digit",
                month: "short"
            }).format(value.time)

            const time = new Intl.DateTimeFormat("en-IN", {
                hour: "2-digit",
                minute: "2-digit"
            }).format(value.time)
            return `${day} ${time}`;
        },
        disableFilters: true
    },
    {
        Header: "Item",
        accessor: (value: CustomerTransactions) => {
            switch (value.itemId) {
                case "cow_milk_ballia":
                case "cow_milk_narahi":
                    return "Cow Milk"
                default: return value.itemName?.toUpperCase()?.replace("_", " ") ?? value.itemId.toUpperCase().replace("_", " ")
            }
        },
        disableFilters: true
    },
    {
        Header: "Quantity",
        accessor: "quantity",
        disableFilters: true
    },
    {
        Header: "Rate",
        accessor: (value: CustomerTransactions) => {
            console.log(value.attributes)
            switch (value.txnType) {
                
                case "COLLECTION":
                    return value.rate +"@" + getCollectionAttributeValue(value)
                default: return value.rate
            }
        },
        disableFilters: true
    },
    // {
    //     Header: "Amount",
    //     accessor: (value: TransactionDataWithBalance) => {
    //         return `${value.amount}`
    //     },
    //     Cell: (props: any) => {
    //         return (
    //             <p style={{ color: props.value > 0 ? 'green' : 'red' }}>{props.value}</p>
    //         );
    //     },
    //     disableFilters: true,
    //     enableHiding: true
    // },
    // {
    //     Header: "Balance",
    //     accessor: (value: TransactionDataWithBalance) => {
    //         return `${value.updatedBalance}`
    //     },
    //     getProps: (state: any, rowInfo: any, column: any) => {
    //         return {
    //             style: {
    //                 color: rowInfo && rowInfo.row.updatedBalance > 0 ? 'green' : 'red',
    //             },
    //         };
    //     },
    //     Cell: (props: any) => {
    //         return (
    //             <p style={{ color: props.value > 0 ? 'green' : 'red' }}>{props.value}</p>
    //         );
    //     },
    //     disableFilters: true
    // },
];

export type LastBillModalProps = {
    wallet: CustomerWallet,
    organizationId: string
}

const LastBillModalComponent = ({ wallet, organizationId }: LastBillModalProps) => {
    const [show, setShow] = useState(false);
    const userType = useContext(UserContext)!!;

    const openModal = () => setShow(true);
    const closeModal = () => setShow(false);
    const [billData, setBillData] = useState<HisabReceipt | undefined>(undefined)
    const [billTransactions, setBillTransactions] = useState<Array<TransactionData> | undefined>(undefined)
    const columns = useMemo(() => CustomerTxnTableColumnsData, [])

    const loadLastReceipt = () => {
        fetchCustomerReceipt(organizationId, wallet.receiptId, userType.user!!.getIdToken()).then((receipt) => {
            setBillData(receipt)
            fetchTransactionsForReceipt(organizationId, receipt.receiptId, receipt?.createdAt, receipt?.from, receipt?.till, wallet.id, userType.user!!.getIdToken()).then((transactions) => {
                setBillTransactions(transactions)
            })
        })
    }

    useEffect(() => {
        if (show) {
            loadLastReceipt()
        }
    }, [show]);
    return (
        <>
            {/* Button to open the modal */}
            <Button type="button" className="btn btn-primary" size='sm' onClick={openModal}>
                <FormattedMessage id="check_last_bill" />
            </Button>

            {/* Modal */}
            {show && (
                <div className="modal fade show d-block" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle"><FormattedMessage id="bill_dated" />: : {getShortDateTimeString(wallet.receiptTillDate)}</h5>
                                <button type="button" className="close" onClick={closeModal} aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="d-flex justify-content-center">
                                    <Card style={{ width: '22rem' }}>
                                        <Card.Body>
                                            <ListGroup variant="flush">
                                                <ListGroup.Item>
                                                    <b className="mx-1">Bill Summary</b>
                                                    (<span className={getNumericTextColor(billData?.billAmount)}>
                                                    <FormattedMessage id="userData_Bill_amount" />: {billData?.billAmount}
                                                </span>)
                                                </ListGroup.Item>
                                                {/* <ListGroup.Item className={getNumericTextColor(billData?.billAmount)}>
                                                    <FormattedMessage id="userData_Bill_amount" />: {billData?.billAmount}
                                                </ListGroup.Item> */}
                                                <ListGroup.Item>
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th><FormattedMessage id="userData_lastBillTable_item" /></th>
                                                            <th><FormattedMessage id="userData_lastBillTable_quantity" /></th>
                                                            <th><FormattedMessage id="userData_lastBillTable_amount" /></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {billData?.itemizedMap && Object.keys(billData?.itemizedMap).map((key) => (
                                                            <tr key={key}>
                                                                <td>{billData?.itemizedMap[key].itemName}</td>
                                                                <td>{billData?.itemizedMap[key].totalQuantity}</td>
                                                                <td>{billData?.itemizedMap[key].totalAmount}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                </ListGroup.Item>
                                                {/* {billData?.itemizedMap && Object.keys(billData?.itemizedMap).map((key) => (
                                                    <ListGroup.Item key={key} className={getNumericTextColor(billData?.itemizedMap[key].totalAmount)}>
                                                        {billData?.itemizedMap[key].itemName}: {billData?.itemizedMap[key].totalQuantity}: {billData?.itemizedMap[key].totalAmount}
                                                </ListGroup.Item>
                                                ))} */}
                                                
                                            </ListGroup>
                                        </Card.Body>
                                    </Card>
                                </div>
                                {billTransactions && billTransactions.length >= 0 ? <>
                        <TableContainer columns={columns} data={billTransactions} />
                    </>
                        : <Spinner animation="border" variant="primary" />
                    }
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={closeModal}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Backdrop overlay */}
            {show && <div className="modal-backdrop fade show" onClick={closeModal}></div>}
        </>
    );
};

export default LastBillModalComponent;
function getCollectionAttributeValue(value: CustomerTransactions) {
    return `f:${parseFloat(value.attributes.f)}, s:${parseFloat(value.attributes.s)}`
}

