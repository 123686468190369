import { ReactNode, useState } from "react"

import { CustomerData } from "../../common/CustomerModel"
import { CustomerContext } from "./customerContext"

type PropsType = {
  children: ReactNode
}

export const CustomerContextProvider = ({children}: PropsType) => {
  const [customerInfo, setCustomerInfo] = useState<CustomerData>()
  const userType = {
    customer: customerInfo,
    setCustomer: setCustomerInfo
  }
  return (
    <CustomerContext.Provider value={userType}>
      {children}
    </CustomerContext.Provider>
  )
}