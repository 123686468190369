
export const messages = {
    "en": {
        hello: "Hello",
        ji: "Ji",
        signin_alreadyLoggedInMesage: "User Already LoggedIn",
        signin_enterPhone: "Enter your Phone Number",
        userData_lastBill: "Last Billing",
        userData_customerAccount: "Account Number",
        signin_sendOTP: "Send One Time Password",
        signin_enterOtpLabel: "Enter OTP Received On Above Mobile",
        signin_enterOtp: "Enter OTP",
        signin_loading_account: "Loading your account info",
        check_last_bill: "Check Bill",
        bill_dated: "Bill Dated",
        userData_lastBillTable_item: "Items",
        userData_lastBillTable_quantity: "Quantity",
        userData_lastBillTable_amount: "Amount",
        userData_Bill_amount: "Bill Amount",
    },
    "hi": {
        hello: "नमस्कार",
        ji: "जी",
        signin_enterPhone: "अपना फोन नंबर डालें",
        userData_lastBill: "पिछला हिसाब",
        userData_lastBill_amount: "पिछला हिसाब राशि",
        userData_Bill_amount: "बिल राशि",
        userData_customerAccount: "खाता संख्या",
        signin_sendOTP: "OTP पासवर्ड भेजे",
        signin_enterOtpLabel: "ऊपर दिये गये मोबाइल पर प्राप्त ओट्स दर्ज करें",
        signin_enterOtp: "OTP डालें",
        signin_loading_account: "आपके खाते की जानकारी लोड हो रही है",
        check_last_bill: "बिल देखें",
        bill_dated: "बिल दिनांक",
        userData_lastBillTable_item: "सामान",
        userData_lastBillTable_quantity: "मात्रा",
        userData_lastBillTable_amount: "राशि",


    }
}