import React, { ReactNode, createContext, useState } from "react"
import { Organization } from "./organizationStore"
import { OrganizationContext } from "./OrganizationContext"
// import { UserContext } from "./userContext"

type PropsType = {
  children: ReactNode
}

export const OrganizationContextProvider = ({children}: PropsType) => {
  const [organizationInfo, setOrganizationInfo] = useState<Organization | undefined>()
  const organizationType = {
    organization: organizationInfo,
    setOrganization: setOrganizationInfo
  }
  return (<OrganizationContext.Provider value={organizationType}>
    {children}
  </OrganizationContext.Provider>)
}