import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './tailwindoutput.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import './common/style/narahiDairyCustomBootstrap.css'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { UserContextProvider } from './modules/signin/UserContextProvider';
import { OrganizationContextProvider } from './modules/organization/OrganizationContextProvider';
import { Body } from './App';
import { CustomerContextProvider } from './modules/userData/CustomerContextProvider';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from "@vercel/speed-insights/react"

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <UserContextProvider>
      <OrganizationContextProvider>
        <CustomerContextProvider>
          <Body />
        </CustomerContextProvider>
      </OrganizationContextProvider>
    </UserContextProvider>
    <SpeedInsights />
    <Analytics />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
