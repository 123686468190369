import { CustomerData, CustomerWallet, HisabReceipt } from "../CustomerModel";
import { TransactionData, TransactionType } from "../TransactionDataModel"

export const BASE_URL_JAVA = "https://backend.narahidairy.com:8445"
// export const BASE_URL_JAVA = "http://localhost:8081"

export async function fetchCustomerInfo(organizationId: string, tokenPromise: Promise<string>): Promise<CustomerData[]> {

  return tokenPromise.then(async (token) => {
    return fetch(`${BASE_URL_JAVA}/customers/info`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + await token,
        'X-Org-Id': organizationId,
      }
    }
    ).then(async (result) => {
      const values = (await result.json()) as Array<CustomerData>
      return values
    });
  });
}

export async function fetchUserWalletDetail(customerId: string, orgId: string, token: string): Promise<CustomerWallet> {
  const searchParam = new URLSearchParams()
  searchParam.append("customerId", customerId)
  searchParam.append("orgId", orgId)
  return fetch(`${BASE_URL_JAVA}/customers/wallet?${searchParam.toString()}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token,
      'X-Org-Id': orgId,
      'X-User-Id': customerId,
    },
    body: JSON.stringify({
      customerId: customerId,
      orgId: orgId
    })
  }
  ).then(async (result) => {
    const values = (await result.json()) as CustomerWallet
    return values
  });
}

export async function fetchCustomerTxnBetween(customerId: string, orgId: string, token: string): Promise<Array<TransactionData>> {
  return fetch(`${BASE_URL_JAVA}/transactions/unBilledTransactions`, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'X-Org-Id': orgId,
      'X-User-Id': customerId,
    }
  }
  ).then(async (result) => {
    const values = (await result.json()) as Array<TransactionData>
    return values
  });
}

/*
curl -X 'POST' \
  'http://localhost:8081/customers/hisab/receipt' \
  -H 'accept: \*\/\*' \
  -H 'Content-Type: application/json' \
  -d '{
  "orgId": "string",
  "receiptId": "string"
}'
*/

export async function fetchCustomerReceipt(orgId: string, receiptId: string, token: Promise<string>): Promise<HisabReceipt> {
  return fetch(`${BASE_URL_JAVA}/customers/hisab/receipt`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + await token,
      'X-Org-Id': orgId,
      'X-User-Id': orgId,
    },
    body: JSON.stringify({
      orgId: orgId,
      receiptId: receiptId
    })
  }
  ).then(async (result) => {
    const values = (await result.json()) as HisabReceipt
    return values
  });
}

export async function fetchTransactionsForReceipt(orgId: string, receiptId: string, receiptCreateTime: number, from: number, till: number, customerId: string, token: Promise<string>): Promise<Array<TransactionData>> {
  return fetch(`${BASE_URL_JAVA}/customers/hisab/receipt/transactions`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + await token,
      'X-Org-Id': orgId,
      'X-User-Id': orgId,
    },
    body: JSON.stringify({
        "orgId": orgId,
        "receiptId": receiptId,
        "receiptCreateTime": receiptCreateTime,
        "from": from,
        "till": till
    })
  }
  ).then(async (result) => {
    const values = (await result.json()) as Array<TransactionData>
    return values
  });
}



