import { LoggedUserStore } from "./userStore";
import { UserContext, UserType } from "./userContext";
import { NonUserHeader } from "../../components/Header";
import { Alert, Button, Form, FormLabel, Spinner } from "react-bootstrap";
import { getAuth, signInWithPopup, GoogleAuthProvider, signInWithRedirect, getRedirectResult, RecaptchaVerifier, signInWithPhoneNumber, ConfirmationResult } from "firebase/auth";
import { generatePath, redirect } from "react-router";
import { LOGIN_ERROR_URL } from "../../common/Constant";
import { auth, provide } from "../../common/config/firebase/FirebaseConfig";
import { useContext, useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import Divider from '@mui/material/Divider';
import { CustomerData } from "../../common/CustomerModel";
import { CustomerContext } from "../userData/customerContext";
import { FormattedMessage, useIntl } from "react-intl";
import { messages } from "../../i18n/messages";
import { FirebaseError } from "firebase/app";

export const signInWithGoogle = async ({ user, setUser }: UserType) => {

    const auth = getAuth();
    await signInWithPopup(auth, provide)
        .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            if (result != null) {
                const credential = GoogleAuthProvider.credentialFromResult(result);
                if (credential == null) {
                    redirect(generatePath(LOGIN_ERROR_URL))
                    console.error("credential null")
                } else {
                    const token = credential.accessToken;
                    // The signed-in user info.
                    const user = result.user;
                    LoggedUserStore.setLoggedInUser(user)
                    setUser(user)
                    sessionStorage.setItem("result", JSON.stringify(result))
                }
                // IdP data available using getAdditionalUserInfo(result)
                window.location.reload()
            }
        }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            redirect(generatePath(LOGIN_ERROR_URL))
            // ...
        });

}


type SigninDataProps = {
    organizationId: string
}

export const Signin = ({ organizationId, ...props }: SigninDataProps) => {
    const { user, setUser } = useContext(UserContext)!!;
    const { setCustomer } = useContext(CustomerContext)!!
    const [phoneNumber, setPhoneNumber] = useState<string>("")
    const [otp, setOtp] = useState<string | undefined>()
    const [waitingSignin, setWaitingSignin] = useState<boolean>(false)
    const [waitForOtp, setWaitForOtp] = useState<boolean>(false)
    const [error, setError] = useState<boolean>(false)
    const [errorType, setErrorType] = useState<string | undefined>(undefined)
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
    const [waitingForCaptcha, setWaitingForCaptcha] = useState(false)
    const [confirmation, setConfirmation] = useState<ConfirmationResult>()
    let [otpSentTime, setOtpSentTime] = useState<number | undefined>(undefined)
    const intl = useIntl()
    let userByMobilePromise: Promise<CustomerData[]>
    const [waitDurationForResend, setWaitDurationForResend] = useState<number>(100000)
    let timer: NodeJS.Timer | undefined = undefined

    const sendOtp = async () => {
        try {
            const currentTime = new Date().getTime()
            if (otpSentTime && (currentTime - otpSentTime) < waitDurationForResend) {
                setError(true)
                setErrorType("waitOtpRetryInterval")
                if(timer) clearInterval(timer)
                timer = setInterval(() => {
                    const currentTime = new Date().getTime()
                    const remainingTime = Math.ceil((waitDurationForResend - (currentTime - (otpSentTime ? otpSentTime: 0))) / 1000)
                    otpSentTime && remainingTime >=0 ? setErrorMessage(`Retry Re-Sending OTP in ${remainingTime} seconds`)
                    : setErrorMessage(`Retry sending OTP`)
                }, 1000)

                setInterval(() => {
                    clearInterval(timer)
                }, waitDurationForResend + 1)
                return
            }
            if (phoneNumber.length != 13) { //including +91 and 10 digit number
                setError(true)
                setErrorMessage("Invalid Mobile Number")
                return
            }

            setWaitForOtp(false)
            setError(false)
            setErrorMessage("")
            // let div = document.getElementById("recaptcha-dev");
            // div?.replaceChildren("")

            setWaitingForCaptcha(true)
            const recaptcha = new RecaptchaVerifier(auth, "recaptcha-dev", {
                'size': 'invisible',
                'callback': (response: any) => {
                    console.log("Recaptcha Verified.")
                }
            })
            const confirmation = await signInWithPhoneNumber(auth, phoneNumber, recaptcha)
            setWaitingForCaptcha(false)
            console.log("Recaptcha Verified.")
            setConfirmation(confirmation)
            setWaitForOtp(true)
            setOtpSentTime(currentTime)
            recaptcha.clear()
        } catch (e: any) {
            console.error(e)
            setError(true)
            setErrorMessage("Error with re-captcha")
        } finally {
            setWaitingForCaptcha(false)
        }
    }

    const confirmOtp = async () => {

        if (otp) {
            setWaitingSignin(true)
            confirmation?.confirm(otp).then(async (user) => {
                if (user && user.user && user.user.phoneNumber == null) {
                    setError(true)
                    setErrorMessage("Phone number is not available")
                    return;
                }
                LoggedUserStore.fetchAndGetCustomer(phoneNumber, organizationId, user.user.getIdToken()).then(customerData => {
                    setError(false)
                    LoggedUserStore.setLoggedInUser(user.user);
                    setUser(user.user);
                    setCustomer(customerData)
                }).catch((e: Error) => {
                    setError(true)
                    setErrorMessage(e.message)
                    return;
                }).finally(() => {
                    setWaitingSignin(false)
                })
            }).catch((e: FirebaseError) => {
                setError(true)
                if (e.code == "auth/invalid-verification-code") {
                    setErrorMessage("Invalid OTP")
                    setWaitForOtp(true)
                } else {
                    console.error(e);
                    setErrorMessage(e.message)
                }
            }).finally(() => {
                setWaitingSignin(false)
            });
        }

        setWaitForOtp(false)
    }

    return (<div>
        <NonUserHeader />
        {user ? <Alert variant="success">
            <FormattedMessage id="alreadyLoggedInMesage" />
        </Alert> : <>
            <div className='signin'>
                {waitingSignin ?
                    <>
                        <div className="d-flex flex-column align-items-center justify-content-center">
                            <div className="row">
                                <Spinner variant="primary" style={{ maxWidth: "15rem", minInlineSize: "10rem", height: "10rem" }} />
                            </div>
                            <div className="row label label-default">
                                <h2>
                                    <FormattedMessage id="signin_loading_account" />
                                </h2>
                            </div>
                        </div>
                    </>

                    :
                    <span>
                        {error ? (errorType == "waitOtpRetryInterval" ? <SigninErrorComponent type={errorType} msg={errorMessage} />
                            : <Alert variant="danger">
                                {errorMessage}
                            </Alert>) : null}

                        <h4><div className="label label-default"><FormattedMessage id="signin_enterPhone" /></div></h4>
                        <PhoneInput
                            placeholder="8574163039"
                            country={"in"}
                            value={phoneNumber}
                            countryCodeEditable={false}
                            onChange={text => setPhoneNumber("+" + text)}
                        />
                        <div style={{ marginTop: 10 }}>
                            <Button variant="primary" onClick={() => sendOtp()} disabled={waitingForCaptcha}>
                                <FormattedMessage id="signin_sendOTP" />

                            </Button>{' '}
                        </div>

                        <div id="recaptcha-dev" style={{ marginTop: 10 }}></div>
                        {
                            waitForOtp ? <>
                                <Divider sx={{ marginTop: 5, marginBottom: 5 }} component="div" />

                                <span style={{ justifyContent: "center", justifyItems: "center" }}>
                                    <Form.Label htmlFor="inputLoginOtp"><FormattedMessage id="signin_enterOtpLabel" /></Form.Label>
                                    <span className="flex" style={{ display: "flex" }}>
                                        <Form.Control
                                            type="number"
                                            id="inputLoginOtp"
                                            placeholder={intl.formatMessage({ id: "signin_enterOtp" })}
                                            style={{ display: "flex", marginRight: "10px" }}
                                            value={otp}
                                            onChange={(event) => setOtp(event.target.value)}
                                        />

                                        <Button variant="success" onClick={() => confirmOtp()}>Confirm</Button>{' '}

                                    </span>
                                </span>
                            </> : null
                        }

                    </span>
                }

            </div>
        </>
        }
    </div>
    )
}

type SigninErrorProps = {
    type: string,
    msg: string | undefined
}

const SigninErrorComponent = ({ type, msg }: SigninErrorProps) => {
    const [errorType, setErrorType] = useState<string>(type)

    if (errorType == "otpResendInterval") {
        return (
            <Alert variant="danger">
                {msg ? msg : `${type} Error !!!`}
            </Alert>
        )
    } else {
        return (
            <Alert variant="danger">
                {msg ? msg : `${type} Error !!!`}
            </Alert>
        )
    }
    return (<div></div>)
}