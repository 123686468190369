// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { PhoneAuthProvider, getAuth } from "firebase/auth";
import env from "react-dotenv";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
let firebaseConfig = {}
if(env.FIREBASE_CONFIG) {
    firebaseConfig = JSON.parse(env.FIREBASE_CONFIG)
}else{
    console.error("firebase err")
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const provide = new PhoneAuthProvider(auth)